import { get } from 'lodash'
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import AppImage from '@theme/components/atom/AppImage'
import AppLink from '@theme/components/atom/AppLink'
import AppTitle from '@theme/components/atom/AppTitle'
import LayoutBase from '@theme/components/shop/LayoutBase'
import NewsletterForm from '@theme/components/shop/NewsletterForm'
import SeoMixin from '~/mixins/SeoMixin'

export default {
  props: {
    homepageConfiguration: {
      type: Object,
      required: true,
    },
  },
  components: {
    AppImage,
    AppLink,
    AppTitle,
    LayoutBase,
    NewsletterForm: hydrateWhenVisible(NewsletterForm),
  },
  head() {
    return this.getSeoHead()
  },
  computed: {
    newsletters() {
      return get(this.homepageConfiguration, 'newsletters') || []
    },
    newsletterPopupsIds() {
      if (this.$env.DEFAULT_LOCALE !== 'sk') {
        return ['1438bbfd7e7746aabd0dba3419b5030a', '030580970aae4eaf8e3610d29b5733d5']
      } else {
        return ['836272fea7b2416eb5f4ac98760aa54c', '7e02526fac8045df83095d049f132a42']
      }
    },
    sections() {
      return get(this.homepageConfiguration, 'sections') || []
    },
  },
  mixins: [SeoMixin],
}
